var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"list-section-wrapper neumorphism-pressed"},[_c('div',{staticClass:"mb-4"},[_c('v-row',[_c('v-col',{staticClass:"notification-filter-section neumorphism-flat",attrs:{"cols":"4"}},[_c('span',{staticClass:"px-3"},[_c('v-icon',[_vm._v("mdi-filter")])],1),_c('div',{staticClass:"notification-filter-wrapper"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.notification_type),expression:"notification_type"}],staticClass:"notification-filter",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.notification_type=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{staticClass:"select-option",attrs:{"value":"bulk"}},[_vm._v("Bulk")]),_c('option',{staticClass:"select-option",attrs:{"value":"individual"}},[_vm._v("Individual")])]),_c('span',{staticClass:"pr-2 notification-filter-arrow"},[_c('v-icon',[_vm._v("mdi-chevron-down")])],1)])])],1)],1),_c('div',[_c('v-data-table',{staticClass:"neumorphism-flat",attrs:{"items":_vm.notification_history_list,"headers":_vm.dynamic_header,"loading":_vm.table_loading,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.customer_details",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"py-2"},[_vm._v(" "+_vm._s(item.full_name)+" ")]),_c('div',{staticClass:"py-2"},[_vm._v(" "+_vm._s(item.mobile)+" ")]),_c('div',{staticClass:"py-2"},[_vm._v(" "+_vm._s(item.email)+" ")])]}},{key:"item.notification_detail.image",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"notification-image pa-3"},[_c('img',{attrs:{"src":item.notification_detail.image}})])]}},{key:"item.notification_detail.body",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","max-width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"body-text-wrapper"},'div',attrs,false),on),[_vm._v(" "+_vm._s(item.notification_detail.body)+" ")])]}}],null,true)},[_c('div',[_vm._v(_vm._s(item.notification_detail.body))])])]}}],null,true)}),_c('div',{staticClass:"mt-4"},[_c('v-pagination',{staticClass:"custom",attrs:{"light":"","circle":"","length":_vm.total_page_count,"total-visible":7},on:{"input":_vm.getNotificationHistoryList},model:{value:(_vm.page_number),callback:function ($$v) {_vm.page_number=$$v},expression:"page_number"}})],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }